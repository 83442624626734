import { Box, Divider } from "@mui/material";
import { Link } from "components/icons";
import { ArrowRight } from "components/icons";
import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import { AccountMappingStatus } from "models/AccountMappingSettings";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import Partnership from "models/Partnership";
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
} from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { loadView, updateView } from "redux/accountMapping/thunks";
import { AccountMappingResource } from "redux/accountMapping/types";
import { selectActivePayingFeatures } from "redux/api/selectors";

import PartnershipOwner from "./PartnershipOwner";
import PartnershipStatus from "./PartnershipStatus";
import PartnershipTags from "./PartnershipTags";

type Props = {
  partnership: Partnership;
  reloadRecord: () => void;
  saving: boolean;
  setSaving: (saving: boolean) => void;
  className?: string;
};

export const PartnershipSettingsOverview = ({
  partnership,
  reloadRecord,
  saving,
  setSaving,
  className,
}: Props) => {
  const { classes, cx } = useStyles();
  const { profile } = useUserProfile();
  const dispatch = useDispatch();
  const history = useHistory();
  const partner = partnership.getPartner(profile);

  const payingFeatures = useSelector(selectActivePayingFeatures);
  const isMatchedAccount = payingFeatures.includes(
    PayingFeature.UseNewAccountMapping
  );

  const partnershipStatus = partnership.isDemo
    ? AccountMappingStatus.Active
    : partnership.getAccountMappingStatus(profile.company);
  const message = getMessage(partnershipStatus);
  const onlyCommonCustomers = partnership.onlyCommonCustomersShared();

  const goToAccountMapping = async () => {
    const partnershipPathname = `/partnerships/${
      partnership.isDemo ? "demo" : partnership.id
    }`;
    await dispatch(loadView({ partnership, isMatchedAccount }));
    await dispatch(
      updateView({
        accountType: isMatchedAccount
          ? AccountMappingResource.matched_accounts
          : AccountMappingResource.matches,
        partnership,
        isMatchedAccount,
      })
    );
    history.push({
      pathname: `${partnershipPathname}/account-mapping`,
      search: `?kpi=${
        onlyCommonCustomers ? "common_customers" : "all_overlaps"
      }`,
    });
  };

  return (
    <Box className={cx(classes.titleContainer, className)}>
      <Box className={classes.flex}>
        <CompanyAvatar
          src={partner.avatarUrl}
          classes={{ avatar: classes.avatar }}
        />
        {profile.canManagePartnerships && (
          <Box className={classes.rightAligned}>
            <PartnershipStatus
              partnership={partnership}
              saving={saving}
              setSaving={setSaving}
            />
          </Box>
        )}
      </Box>
      <Box className={classes.marginBottom}>
        <T h2 bold>
          {partner.name}
        </T>
        <T className={classes.textWithIcon}>
          <a
            className={classes.link}
            href={partner.websiteUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Link className={classes.icon} />
            {partner.websiteUrl}
          </a>
        </T>
      </Box>
      <PartnershipTags
        partnership={partnership}
        reloadPartnership={reloadRecord}
      />
      <Divider className={classes.divider} />
      <Box className={cx(classes.row, classes.marginBottomSmall)}>
        <CompanyAvatar
          company={profile.company}
          classes={{ avatar: classes.avatarSmall }}
        />
        <T className={classes.alphaText}>
          <FormattedMessage {...i18n.partnershipOwner} />
        </T>
        <Box className={classes.rightAligned}>
          {profile.canManagePartnerships ? (
            <PartnershipOwner partnership={partnership} />
          ) : (
            <T bold>{partnership.partnershipOwner?.fullName ?? "--"}</T>
          )}
        </Box>
      </Box>
      <Box className={classes.row}>
        <CompanyAvatar
          src={partner.avatarUrl}
          classes={{ avatar: classes.avatarSmall }}
        />
        <T className={classes.alphaText}>
          <FormattedMessage {...i18n.partnershipOwner} />
        </T>
        <Box className={classes.rightAligned}>
          <T bold>{partnership.partnerPartnershipOwner?.fullName ?? "--"}</T>
        </Box>
      </Box>
      <Divider className={classes.divider} />
      <T>
        <FormattedMessage {...message} values={{ partner: partner.name }} />
      </T>
      <Box className={classes.button}>
        <Button
          label={i18n.goToAccountMapping}
          RightIcon={ArrowRight}
          onClick={goToAccountMapping}
        />
      </Box>
    </Box>
  );
};

const getMessage = (status: AccountMappingStatus): MessageDescriptor => {
  switch (status) {
    case AccountMappingStatus.Active:
      return i18n.active;
    case AccountMappingStatus.RequestedByPartner:
      return i18n.requestedByPartner;
    case AccountMappingStatus.RequestedByCompany:
      return i18n.requestedByCompany;
    case AccountMappingStatus.Inactive:
      return i18n.inactive;
  }
  throw new Error("Un-mapped status: " + status);
};

const useStyles = makeStyles()((theme) => ({
  alphaText: {
    color: theme.palette.alpha500,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "15.6px",
  },
  avatar: {
    marginBottom: theme.spacing(1.5),
  },
  avatarSmall: {
    height: 16,
    marginRight: theme.spacing(1),
    width: 16,
  },
  button: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  content: {
    width: "68%",
    paddingRight: theme.spacing(4),
  },
  divider: {
    margin: theme.spacing(2, 0),
    color: theme.palette.greyLight050,
  },
  flex: {
    display: "flex",
  },
  icon: {
    height: 16,
    width: 16,
    verticalAlign: "middle",
    marginRight: theme.spacing(0.75),
  },
  link: {
    color: theme.palette.midnight,
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  marginBottomSmall: {
    marginBottom: theme.spacing(1),
  },
  rightAligned: {
    marginLeft: "auto",
  },
  root: {
    marginTop: theme.spacing(4.5),
  },
  rowGap: {
    rowGap: theme.spacing(2),
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  side: {
    width: "32%",
    padding: theme.spacing(0, 4),
  },
  textWithIcon: {
    display: "block",
    width: "100%",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 8,
    backgroundColor: theme.palette.ivory,
    padding: theme.spacing(2.5),
  },
}));

const i18n = defineMessages({
  partnershipOwner: {
    id: "PartnershipSettings.PartnershipSettingsOverview.partnershipOwner",
    defaultMessage: "Partnership Owner",
  },
  active: {
    id: "PartnershipSettings.PartnershipSettingsOverview.active",
    defaultMessage:
      "Your company and {partner} are sharing a detailed Account Mapping.",
  },
  goToAccountMapping: {
    id: "PartnershipSettings.PartnershipSettingsOverview.goToAccountMapping",
    defaultMessage: "Go to account mapping",
  },
  inactive: {
    id: "PartnershipSettings.PartnershipSettingsOverview.inactive",
    defaultMessage: "Ready to share a detailed account mapping with {partner}?",
  },
  requestedByCompany: {
    id: "PartnershipSettings.PartnershipSettingsOverview.requestedByCompany",
    defaultMessage:
      "{partner} has not accepted yet your invitation to share a detailed Account Mapping.",
  },
  requestedByPartner: {
    id: "PartnershipSettings.PartnershipSettingsOverview.requestedByPartner",
    defaultMessage:
      "{partner} has invited you to share a detailed Account Mapping.",
  },
});
